var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-0" },
    [
      _c("h4", {
        staticClass: "mb-2",
        domProps: {
          innerHTML: _vm._s(_vm.$t("applications.non-applicant-label"))
        }
      }),
      _vm._l(_vm.items, function(item, index) {
        return _c("v-checkbox", {
          key: index,
          staticClass: "my-0 py-0",
          attrs: {
            id: "type_" + item.value,
            "input-value": _vm.applicantType,
            label: item.text,
            value: item.value,
            "hide-details": ""
          },
          on: { change: _vm.handleChangeApplicantType }
        })
      }),
      !_vm.hideActions
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.handleContinue }
                    },
                    [_vm._v(_vm._s(_vm.$t("applications.continue")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }