<template>
    <div class="pt-0">
        <h4 class="mb-2" v-html="$t('applications.non-applicant-label')"></h4>
        <v-checkbox
            :id="`type_${item.value}`"
            :input-value="applicantType"
            :label="item.text"
            :value="item.value"
            @change="handleChangeApplicantType"
            v-for="(item, index) in items"
            hide-details
            :key="index"
            class="my-0 py-0"
        ></v-checkbox>
        <v-row v-if="!hideActions">
            <v-col class="d-flex">
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mb-3" @click="handleContinue">{{
                    $t("applications.continue")
                }}</v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "applicant-type-select",
    components: {},
    props: {
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        applicantType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Set the Checkbox selection
            items: [
                {
                    value: "Applicant",
                    text: this.$t("applications.financially-responsible"),
                },
                {
                    value: "Non Applicant",
                    text: this.$t("applications.non-financially-responsible"),
                },
            ],
        };
    },
    methods: {
        handleChangeApplicantType(type) {
            this.$emit("update:applicantType", type);
        },
        handleContinue() {
            this.$emit("continue");
        },
    },
};
</script>

<style scoped>
</style>