import { render, staticRenderFns } from "./ApplicantTypeSelect.vue?vue&type=template&id=5127c286&scoped=true&"
import script from "./ApplicantTypeSelect.vue?vue&type=script&lang=js&"
export * from "./ApplicantTypeSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5127c286",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VCol,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5127c286')) {
      api.createRecord('5127c286', component.options)
    } else {
      api.reload('5127c286', component.options)
    }
    module.hot.accept("./ApplicantTypeSelect.vue?vue&type=template&id=5127c286&scoped=true&", function () {
      api.rerender('5127c286', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/application/components/Application/Setup/ApplicantTypeSelect.vue"
export default component.exports